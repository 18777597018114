<template>
  <div class="players view">
    <app-header :title="__('organizationMembershipLogs')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search mr ml">
        <select-reference
            v-model="playerId"
            :endpoint="'/ingame/civilization/player'"
            :field-icon="'avatar'"
            :nothing-selected-message="__('filterByPlayer')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="childOrganizationId"
            :endpoint="'/ingame/civilization/organization'"
            :field-icon="'logo'"
            :nothing-selected-message="__('filterByChildOrganization')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="organizationId"
            :endpoint="'/ingame/civilization/organization'"
            :field-icon="'logo'"
            :nothing-selected-message="__('filterByOrganization')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh()" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="organizationMembershipLogs"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @sort="sort"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";

  const MEMBERSHIP_TYPE_LABELS = [
    'Member',
    'Legate',
    'Superlegate'
  ]

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      AppHeader,
      DataTable,
    },
    data: () => ({
      loading: false,
      organizationMembershipLogs: [],
      playerId: null,
      childOrganizationId: null,
      organizationId: null,
      dataTableFields: [],
      recordActions: [],
    }),
    watch: {
      playerId() {
        this.pagination.page = 1;
      },
      childOrganizationId() {
        this.pagination.page = 1;
      },
      organizationId() {
        this.pagination.page = 1;
      },
    },
    methods: {
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        if (this.playerId !== null) {
          params.playerIds = this.playerId;
        }
        if (this.childOrganizationId !== null) {
          params.childOrganizationIds = this.childOrganizationId;
        }
        if (this.organizationId !== null) {
          params.organizationIds = this.organizationId;
        }
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/civilization/organizationmembership/logs', { params }).then(async (res) => {
          this.organizationMembershipLogs = res.data;
          this.updatePagination(res);
          await this.enrichRecords();
          this.loading = false;
          this.showInfoNotification('Logs loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        const orgIds = [];
        const playerIds = [];
        this.organizationMembershipLogs.forEach(({ organizationId, childOrganizationId, playerId }) => {
          if (!orgIds.includes(organizationId)) {
            orgIds.push(organizationId);
          }
          if (childOrganizationId !== null && !orgIds.includes(childOrganizationId)) {
            orgIds.push(childOrganizationId);
          }
          if (playerId !== null && !playerIds.includes(playerId)) {
            playerIds.push(playerId);
          }
        });
        let organizations = [];
        let players = [];
        if (orgIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/organization/(${orgIds.join(',')})`, {
            params: {
              fields: 'id,name,logo'
            },
          }).then((res) => {
            organizations = res.data;
            this.showInfoNotification('Organizations have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        if (playerIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/player/(${playerIds.join(',')})`, {
            params: {
              fields: 'id,name,avatar'
            },
          }).then((res) => {
            players = res.data;
            this.showInfoNotification('Players have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }

        this.organizationMembershipLogs = this.organizationMembershipLogs.map(membership => {
          const organization = organizations.find(organization => organization.id === membership.organizationId);
          membership.organizationLogo = organization.logo;
          membership.organization = organization.name;
          if (membership.childOrganizationId !== null) {
            const childOrganization = organizations.find(organization => organization.id === membership.childOrganizationId);
            membership.childOrganizationLogo = childOrganization.logo;
            membership.childOrganization = childOrganization.name;
          }
          if (membership.playerId !== null) {
            const player = players.find(playerId => playerId.id === membership.playerId);
            membership.avatar = player.avatar;
            membership.player = player.name
          }
          return membership;
        });
      },
    },
    created() {
      this.setPageTitle(this.__('organizationMembershipLogs'));
      if ('organizationId' in this.$route.query) {
        const orgId = this.$route.query.organizationId;
        if (orgId !== null && orgId !== undefined) {
          this.organizationId = orgId;
        }
      }
      if ('childOrganizationId' in this.$route.query) {
        const orgId = this.$route.query.childOrganizationId;
        if (orgId !== null && orgId !== undefined) {
          this.childOrganizationId = orgId;
        }
      }
      if ('playerId' in this.$route.query) {
        const playerId = this.$route.query.playerId;
        if (playerId !== null && playerId !== undefined) {
          this.playerId = playerId;
        }
      }
      this.dataTableFields = [
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "avatar", title: 'Avatar', type: "srcOrSvg", disableSorting: true },
        { name: "player", title: this.__('player'), type: "default", disableSorting: true },
        { name: "childOrganizationLogo", title: this.__('Logo'), type: "srcOrSvg", disableSorting: true },
        { name: "childOrganization", title: this.__('childOrganization'), type: "default", disableSorting: true },
        { name: "organizationLogo", title: this.__('Logo'), type: "srcOrSvg", disableSorting: true },
        { name: "organization", title: this.__('organization'), type: "default", disableSorting: true },
        { name: "type", title: this.__('membershipType'), type: "enum", values: MEMBERSHIP_TYPE_LABELS },
        { name: "joinedAt", title: this.__('joinedAt'), type: "datetime" },
        { name: "leftAt", title: this.__('leftAt'), type: "datetime" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [];
      this.refresh();
    }
  }
</script>
